/* eslint-disable react/prop-types */
import cx from 'classnames';
import { useRouter } from 'next/router';
import { func, shape } from 'prop-types';
import { useEffect, useState } from 'react';

import { Link } from '#atoms/index';
import { event, eventTimer } from '#helpers/gtag';
import IconHomeUser from '#app/UI/atoms/icons/homeUser';
import IconProfileUser from '#app/UI/atoms/icons/profileUser';
import IconDashboardNew from '#app/UI/atoms/icons/DashboardNew';
import IconLogoutUserNew from '#app/UI/atoms/icons/logoutUserNew';

const UserDropdown = ({ data, onClickLogout, isLetterAvatar = false }) => {
  const router = useRouter();
  const [showOptions, setShowOptions] = useState(false);
  const userData = JSON.parse(localStorage.getItem('userData'));
  const organizationId = localStorage.getItem('organization_id');
  const platform = localStorage.getItem('platform') || null;

  useEffect(() => {
    if (!platform) {
      onClickLogout();
    }
  }, [platform]);

  const eventsAnalytics = () => {
    event(
      'acceso-perfiles',
      'menu-desplegable',
      'clic en banner informativo en zona de padres'
    );
    event(
      userData ? `acceso-perfiles ${userData.email}` : 'acceso-perfiles',
      'menu-desplegable',
      'clic en banner informativo en zona de padres'
    );
    eventTimer(`tiempo-lectura-perfiles ${userData.email}`, 'menu-desplegable');
  };

  return (
    <div className="user-dropdown">
      {/* Trigger */}
      <button
        className={cx('user-dropdown__trigger', {['user-dropdown__trigger--active']: showOptions})}
        onClick={() => setShowOptions(!showOptions)}
      >
        {/* Name */}
        <span className="user-dropdown__trigger_username">{data.firstName}</span>

        {/* Logo */}
        {isLetterAvatar && (
          <div className="user-dropdown__trigger_logo">
            <p className="user-dropdown__trigger_logo_word">{data.firstName[0]}</p>
          </div>
        )}
      </button>

      {/* Options */}
      {showOptions && (
        <div className="user-dropdown__options">
          {platform === 'zona-padres' ? (
            <>
              {router.pathname.includes('/dashboard') ? (
                <>
                  <div className="user-dropdown__option">
                    <IconHomeUser className="user-dropdown__icon-desktop" />
                    <Link
                      href="/"
                      gtagActionClick1="acceso-homepage"
                      gtagCategoryClick1="menu-desplegable"
                      gtagActionClick2={
                        userData ? `acceso-homepage ${userData.email}` : 'acceso-homepage'
                      }
                      gtagCategoryClick2="menu-desplegable"
                    >
                      Homepage
                    </Link>
                  </div>
                  <div className="user-dropdown__option">
                    <IconProfileUser className="user-dropdown__icon-desktop" />
                    <Link href="/login-perfiles" eventName="perfiles_menu">
                      <div onClick={eventsAnalytics}>Perfiles</div>
                    </Link>
                  </div>
                </>
              ) : (
                <div className="user-dropdown__option">
                  <IconProfileUser className="user-dropdown__icon-desktop" />
                  <Link href="/login-perfiles" eventName="perfiles_menu">
                    <div onClick={eventsAnalytics}>Perfiles</div>
                  </Link>
                </div>
              )}
            </>
          ) : (
            <>
              <div className="user-dropdown__option">
                <IconDashboardNew className="user-dropdown__icon-desktop" />
                <Link
                  href={`/dashboardb2b/organization/${organizationId}`}
                  eventName="perfiles_menu"
                >
                  <div>Ir al dashboard</div>
                </Link>
              </div>
            </>
          )}
          <div className="user-dropdown__option">
            <IconLogoutUserNew className="user-dropdown__icon-desktop" />
            <button className="user-dropdown__button-logout" onClick={onClickLogout}>
              Cerrar Sesión
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

UserDropdown.propTypes = {
  data: shape(),
  onClickLogout: func
};

export default UserDropdown;
