import Link from 'next/link';

import { LogoSVGDark, LogoSVGWhite } from '#helpers/main-logo';

const Logo = ({ color, alianza, hasAlliance }) => (
  <Link href={'/'} passHref>
    <a>
      <picture className="logo" aria-label="logo | Crack The Code">
        {color == 'dark' && <LogoSVGDark />}
        {color == 'white' && <LogoSVGWhite />}
      </picture>
    </a>
  </Link>
);

export default Logo;
