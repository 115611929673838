/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import { useRouter } from 'next/router';

import { ShapeTab, Paragraph } from '#styleguide/index';
import { UserDropdown } from '#components/index';

import GlobalContext from '../../context/Global/GlobalContext';

const ContainerTabChild = ({
  tabs,
  currentTab,
  userContextData,
  setCurrentTab,
  hasContentRight,
  setObjectCalendar
}) => {
  const {
 cleanPrevPath, isLoginUser, updateCurrentPosition, cleanPurchaseData 
} =
    useContext(GlobalContext);
  const router = useRouter();

  const selectTab = idx => () => {
    setCurrentTab(idx);
  };

  const onClickLogout = async () => {
    window.localStorage.removeItem('userData');
    window.localStorage.removeItem('accessToken');
    window.localStorage.removeItem('refreshToken');
    window.localStorage.removeItem('platform');

    await router.push('/');
    if (router.pathname === '/') {
      router.reload();
    }
    isLoginUser(false);
  };

  return (
    <div className="ui-container-tab-child">
      <div className="ui-container-tab-child__content-tabs">
        <div className="ui-container-tab-child__items-tab">
          {tabs?.map((tab, idx) => (
            <ShapeTab
              key={idx}
              name={tab?.name}
              icon={tab?.icon}
              isActive={currentTab === idx}
              onClick={selectTab(idx)}
            />
          ))}
        </div>
        {hasContentRight && (
          <div className="ui-container-tab-child__right">
            {!userContextData?.loading && userContextData?.data && (
              <UserDropdown
                data={userContextData.data}
                onClickLogout={onClickLogout}
                isLetterAvatar={true}
              />
            )}
          </div>
        )}
      </div>
      <div className="ui-container-tab-child__item-content">
        {tabs[currentTab]?.childrenComponent}
        <div className="ui-container-tab-child__item-content-footer">
          <hr />
          <section className="ui-container-tab-child__footer-info">
            <Paragraph size="16">©2022 Crack The Code. All Rights Reserved.</Paragraph>
          </section>
        </div>
      </div>
    </div>
  );
};

export default ContainerTabChild;
