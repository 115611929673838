const IconDashboardNew = ({
  fill = '#171A1A',
  className,
  dataTestid = 'home-dashboard'
}) => (
  <svg
    className={className}
    data-testid={dataTestid}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_2453_1634"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="16"
    >
      <rect width="16" height="16" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_2453_1634)">
      <path
        d="M3.33333 14C2.96667 14 2.65278 13.8694 2.39167 13.6083C2.13056 13.3472 2 13.0333 2 12.6667V3.33333C2 2.96667 2.13056 2.65278 2.39167 2.39167C2.65278 2.13056 2.96667 2 3.33333 2H12.6667C13.0333 2 13.3472 2.13056 13.6083 2.39167C13.8694 2.65278 14 2.96667 14 3.33333V12.6667C14 13.0333 13.8694 13.3472 13.6083 13.6083C13.3472 13.8694 13.0333 14 12.6667 14H3.33333ZM6.66667 12.6667V8.66667H3.33333V12.6667H6.66667ZM8 12.6667H12.6667V8.66667H8V12.6667ZM3.33333 7.33333H12.6667V3.33333H3.33333V7.33333Z"
        fill={fill}
      />
    </g>
  </svg>
);

export default IconDashboardNew;
